<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <div class="Enter-top">
        <div>
          <a-breadcrumb style="margin: 18px 0">
            <a-breadcrumb-item>品牌管理</a-breadcrumb-item>
            <a-breadcrumb-item>本地品牌库</a-breadcrumb-item>
          </a-breadcrumb>
        </div>
        <div v-if="visible == ''" class="Enter-top-right">
          <a-button
            style="margin: 0 15px"
            type="primary"
            @click.prevent="showDrawer"
          >
            新增品牌
          </a-button>
          <a-button type="danger" @click.prevent="onDetele"> 删除 </a-button>
        </div>
        <div v-else>
          <a-icon
            @click="
              () => {
                this.Id = ''
                this.visible = false
              }
            "
            style="
              width: 30px;
              margin-right: 5px;
              cursor: pointer;
              font-size: 25px;
            "
            type="rollback"
          />
        </div>
      </div>
    </a-layout-content>
    <a-layout
      v-if="!visible"
      style="padding: 24px; height: 100%; overflow: auto"
    >
      <a-layout-content
        style="
          background-color: #fff;
          line-height: 60px;
          margin: 0;
          width: 100%;
        "
      >
        <a-form
          style="padding: 0 24px"
          class="ant-advanced-search-form"
          :form="fom"
          @submit="handleSearch"
        >
          <a-row class="flex-d-row" style="flex-wrap: wrap" :gutter="24">
            <a-col v-for="(i, index) in textlist" :key="index">
              <a-form-item style="margin-bottom: 0" :label="i">
                <a-cascader
                  v-if="i == '业态'"
                  :field-names="{
                    label: 'name',
                    value: 'id',
                    children: 'children',
                  }"
                  style="width: 100%"
                  :options="BusinessTypes"
                  placeholder="请选择业态"
                  v-model="form.businessTypeIds"
                  change-on-select
                  @change="onChangetype"
                />
                <a-date-picker
                  style="width: 100%"
                  v-else-if="i == '创建时间'"
                  v-model="params.create_time.value"
                />
                <a-input
                  v-else
                  v-decorator="[
                    `${i}`,
                    {
                      rules: [],
                    },
                  ]"
                  :placeholder="'请输入' + i"
                />
              </a-form-item>
            </a-col>
            <a-col class="a-col-top">
              <a-button type="primary" html-type="submit"> 搜索 </a-button>
              <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                清空
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </a-layout-content>
      <a-layout-content
        :style="{
          background: '#fff',
          padding: '24px',
          margin: 0,
        }"
      >
        <!-- 表格 -->
        <div class="search-wrap">
          <a-table
            :row-selection="rowSelection"
            rowKey="id"
            :pagination="false"
            :columns="columns"
            :data-source="nativelist"
            :rowClassName="rowClassName"
          >
            <span slot="time" slot-scope="text">
              {{ text | time }}
            </span>
            <span slot="yetai" slot-scope="text">{{
              text == 2054 ? '高端' : text == 2055 ? '中高端' : '大众化'
            }}</span>
            <template slot="action" slot-scope="text, record">
              <a @click="onEdit(record)">编辑</a>
            </template>
          </a-table>
          <a-pagination
            v-model="params.current"
            :page-size="params.pageSize"
            show-quick-jumper
            :default-current="2"
            :total="total"
            @change="onpagesize"
          />
        </div>
      </a-layout-content>
    </a-layout>
    <a-layout v-else style="padding: 24px; height: 100%; overflow: auto">
      <a-layout-content style="background: #fff">
        <div class="content-title">
          <span>基础信息</span>
        </div>
        <div class="content-body">
          <a-form-model-item style="width: 40%" label="业态">
            <a-cascader
              :field-names="{
                label: 'name',
                value: 'id',
                children: 'children',
              }"
              style="width: 100%"
              :options="BusinessTypes"
              placeholder="请选择业态"
              v-model="form.businessTypeIds"
              change-on-select
              @change="onChangetype"
            />
          </a-form-model-item>
          <a-form-model-item style="width: 40%" label="品牌名称">
            <a-input v-model="form.brandName" placeholder="请输入品牌名称" />
          </a-form-model-item>
        </div>
      </a-layout-content>
      <!-- 图片 -->
      <a-layout-content style="background: #fff; margin: 24px 0; height: 217px">
        <div class="content-title">
          <span>图片</span>
        </div>
        <div class="content-body">
          <div class="clearfix">
            <a-upload
              action=""
              list-type="picture-card"
              :file-list="form.logoPic"
              @preview="handlePreview"
              :customRequest="handleChangeinfo"
              @change="handleChangeinfos"
            >
              <div v-if="form.logoPic.length < 1">
                <a-icon type="plus" />
                <div class="ant-upload-text">上传图片</div>
              </div>
            </a-upload>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="handleCancel"
            >
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </div>
        </div>
      </a-layout-content>
      <!-- 扩展信息 -->
      <a-layout-content
        :style="{
          background: '#fff',
          margin: 0,
        }"
      >
        <div class="content-title">
          <span>扩展信息</span>
          <a-icon
            v-if="extension == 0"
            @click="
              () => {
                this.extension = 1
              }
            "
            style="cursor: pointer"
            type="down"
          />
          <a-icon
            v-else
            @click="
              () => {
                this.extension = 0
              }
            "
            style="cursor: pointer"
            type="up"
          />
        </div>
        <div v-show="extension == 1">a</div>
      </a-layout-content>
    </a-layout>
    <a-layout-content
      v-if="visible == true"
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 70px;
        text-align: center;
      "
    >
      <div save-draw>
        <a-button type="primary" @click="onSubmit"> 提交 </a-button>
        <a-button @click="onClose" style="margin-left: 40px"> 取消 </a-button>
      </div>
    </a-layout-content>
  </a-layout>
</template>
<script>
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
const OSS = require('ali-oss')
let client
import { nanoid } from 'nanoid'
const columns = [
  {
    title: '品牌名称',
    dataIndex: 'brandName',
  },
  {
    title: '公司名称',
    dataIndex: 'companyName',
  },
  {
    title: '品牌定位',
    dataIndex: 'listIndustryType3',
    scopedSlots: { customRender: 'yetai' },
  },
  {
    title: '需求面积',
    dataIndex: 'mianJiMax',
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'time' },
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
import http from '../../../http'
import { imageMixin } from '../../../mixins'
export default {
  mixins: [imageMixin],
  data() {
    return {
      previewVisible: false,
      previewImage: '',
      loading: false,
      extension: 0,
      columns,
      title: '',
      visible: false,
      placement: 'right',
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      textlist: ['品牌名称', '公司名称', '业态', '需求面积', '创建时间'],
      expand: false,
      fom: this.$form.createForm(this, { name: 'advanced_search' }),
      params: {
        current: 1,
        pageSize: 10,
        brand_name: {
          value: '',
          op: '%like%',
        },
        company_name: {
          value: '',
          op: '%like%',
        },
        mian_ji_max: {
          value: undefined,
          op: '=',
        },
        create_time: {
          value: '',
          op: '=',
        },
        business_type_id: {
          value: '',
          op: '=',
        },
      },
      total: 0,
      value: 'brandName',
      key: '',
      res: {
        credit: {
          securityToken: '',
          accessKeySecret: '',
          accessKeyId: '',
        },
        region: '',
      },
      nativelist: [],
      businessTypes: [],
      selectedRowKey: [],
      Id: '',
      form: {
        id: '',
        logoPic: [],
        businessTypeIds: [],
        businessTypeId: '',
        brandName: '',
      },
    }
  },
  filters: {
    time(time) {
      var times = time.split('T')
      return times[0] + ' ' + times[1]
    },
  },
  methods: {
    handleSearch(e) {
      e.preventDefault()
      this.fom.validateFields((error, values) => {
        this.params.brand_name.value = values.品牌名称
        this.params.company_name.value = values.公司名称
        this.params.mian_ji_max.value = values.需求面积
      })
      this.getNative()
    },

    handleReset() {
      this.fom.resetFields()
    },

    toggle() {
      this.expand = !this.expand
    },
    onChangetype(e) {
      if (this.visible == false) {
        this.params.business_type_id.value = e[e.length - 1]
        return
      }
      this.form.businessTypeId = e[e.length - 1]
    },
    handleCancel() {
      this.previewVisible = false
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    async getsts() {
      try {
        const res = await http.getsts()
        // console.log(res);
        this.res.credit.securityToken = res.data.data.credit.securityToken
        this.res.credit.accessKeySecret = res.data.data.credit.accessKeySecret
        this.res.credit.accessKeyId = res.data.data.credit.accessKeyId
        this.res.region = res.data.data.region
        client = new OSS({
          region: this.res.region,
          accessKeyId: this.res.credit.accessKeyId,
          accessKeySecret: this.res.credit.accessKeySecret,
          stsToken: this.res.credit.securityToken,
          // 填写Bucket名称。
          bucket: 'sccncloud2',
        })
      } catch (e) {
        console.error(e)
      }
    },
    handleChangeinfos({ fileList }) {
      if (this.form.logoPic.length > fileList.length) {
        this.form.logoPic = fileList
      }
    },
    handleChangeinfo(file) {
      var data = file.file
      this.putObject(client, nanoid(), file.file.name.split('.')[1], data)
    },
    async putObject(client, id, name, data) {
      try {
        const result = await client.put(`sccn/${id}.${name}`, data)
        let url
        url = client.signatureUrl(result.name)
        var a = [result]
        a.forEach(item => {
          this.form.logoPic.push({
            bucket: 'sccncloud2',
            name: item.name,
            uid: id,
            status: 'done',
            url: url,
          })
        })
      } catch (e) {
        console.log(e)
      }
    },
    onClose() {
      this.visible = false
      this.Id = ''
      this.form = {
        id: '',
        logoPic: [],
        businessTypeId: '',
        brandName: '',
      }
    },
    onSubmit() {
      //   console.log("submit!", this.form);
      this.form.businessTypeIds = JSON.stringify(this.form.businessTypeIds)
      this.form.logoPic = JSON.stringify(this.form.logoPic)
      if (this.Id == '') {
        this.AddNative(this.form)
      } else if (this.Id != '') {
        this.putNative(this.form)
      }
    },
    // 删除
    onDetele() {
      let that = this
      this.$confirm({
        title: '确认要删除该本地品牌库么?',
        okText: '是',
        cancelText: '否',
        onOk() {
          setTimeout(() => {
            that.deleteNative()
          }, 100)
        },
        onCancel() {
          console.log()
        },
      })
    },
    async deleteNative() {
      try {
        const res = await http.deleteNative(this.selectedRowKey)
        const { success } = res.data
        if (success) {
          //   console.log(res);
          this.onClose()
          this.timer()
          this.$$message.success('操作成功')
        }
        this.timer()
      } catch (ex) {
        // console.log(this.selectedRowKey);
        console.log('error:' + ex)
      }
    },
    // 编辑
    onEdit(item) {
      this.Id = item.id
      this.form.id = item.id
      this.visible = true
      this.getNativeId(item.id)
    },
    async putNative(form) {
      try {
        const res = await http.putNative(form)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('操作成功')
          this.timer()
          this.onClose()
        } else {
          this.$message.success(msg)
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    onpagesize(e) {
      this.params.current = e
      this.getNative()
      // console.log(this.params.current);
    },
    showDrawer() {
      this.visible = true
      this.title = '新增'
      this.$store.commit('selectKeys')
    },
    async getNative() {
      try {
        const res = await http.getNative(this.params)
        const { success, data } = res.data
        if (success) {
          this.nativelist = data.brands.records
          this.total = data.brands.total
          //   console.log(data);
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getNativeId(id) {
      try {
        const res = await http.getNativeId(id)
        const { success, data } = res.data
        if (success) {
          this.form = data.brand
          if (data.brand.logoPic == null) {
            this.form.logoPic = []
            return
          }
          this.form.logoPic = JSON.parse(data.brand.logoPic)
          var a = []
          this.form.logoPic.forEach(item => {
            let url
            url = client.signatureUrl(item.name)
            a.push({
              bucket: 'sccncloud2',
              name: item.name,
              uid: nanoid(),
              status: 'done',
              url: url,
            })
          })
          this.form.logoPic = a
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    // 新增
    async AddNative(form) {
      try {
        const res = await http.AddNative(form)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('操作成功')
          this.timer()
          this.onClose()
        } else {
          this.$message.error(msg)
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async BusinessTypelist() {
      try {
        const res = await http.BusinessTypelist()
        const { success, data } = res.data
        if (success) {
          this.BusinessTypes = data.businessTypes
          //   console.log(data.businessTypes);
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    timer() {
      return setTimeout(() => {
        this.getNative()
      }, 200)
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = null
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
    handleChange(value) {
      console.log(value)
    },
  },
  mounted() {
    this.getNative()
    this.getsts()
    this.BusinessTypelist()
    if (this.$route.query.visible == 'true') {
      this.showDrawer()
    }
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKey, selectedRows) => {
          this.selectedRowKey = selectedRowKey
          //   console.log("selectedRowKey:" + this.selectedRowKey);
          console.log('selectedRows:', selectedRows)
        },
      }
    },
  },
}
</script>
<style lang="less" scoped>
.search-wrap {
  width: 100%;
  /* background: #999; */
  //   box-shadow: 0 6px 12px 0 rgb(0 0 0 / 4%);
  border-radius: 3px;
  position: relative;
  min-height: 700px;
  height: 100%;
  padding: 10px 0 6px;
  margin-bottom: 15px;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.ant-layout {
  height: 100%;
}
.flex-d-row {
  display: flex;
  flex-direction: row;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .Enter-top-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    .top-right-inpot {
      height: 32px;
      font-size: 13px;
      color: orange;
      border-radius: 22px;
      margin: 0 10px;
      border: 1px solid orange;
    }
  }
}
.content-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 24px;
  border-bottom: 1px solid #eaeaea;
}
.content-body {
  padding: 24px;
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  padding-left: 10px;
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
.ant-form-item {
  margin-bottom: 15px;
}
.a-col-top {
  position: absolute;
  right: 0;
  top: 43px;
}
::v-deep .ant-form-item label {
  margin-right: 16px;
  font-weight: 500;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
</style>
